import React, {useEffect, useContext } from "react"
import Header from './header/Header'
import Login from './login/Login'
import Footer from './footer/Footer';
import UserDetail from "./userDetail/UserDetail";
import BoothDetail from "./boothDetail/BoothDetail";
import Review from "./review/Review";
import Success from "./success/Success";
import LimitExceeded from "./Limitexceeded/LimitExceeded";
import Profile from "./profile/Profile";
import EditProfile from "./editProfile/EditProfile";
import Program from "./program/Program";
import Home from "./home/Home";
import ReportComponent from "./report/report.component";
import {useRoutes} from "react-router-dom";

import {CameratoggleContext} from "../context/cameratoggle-context";



function Index() {
    const routes = useRoutes([
        {
            path: '/',
            element: <Home />
        },
        {
            path: '/login',
            element: <Login />
        },
        {
            path: '/user-detail',
            element: <UserDetail/>
        },
        {
            path: '/booth-detail',
            element: <BoothDetail/>
        },
        {
            path: '/review',
            element: <Review/>
        },
        {
            path: '/success',
            element: <Success />
        },
        {
            path: '/limit-exceeded',
            element: <LimitExceeded/>
        },
        {
            path: '/profile',
            element: <Profile />
        },
        {
            path: '/edit-profile',
            element: <EditProfile />
        },
        {
            path: '/program',
            element: <Program />
        },
        {
            path: '/report',
            element: <ReportComponent/>
        }
    ]);
    return routes;
}

const App = () => {
    const { togglefooter } = useContext(CameratoggleContext);

    return(
            <div>
                <Header/>
                <div className="content-container"><Index /></div>
                <div className="footer--pin">{ !togglefooter && <Footer /> }</div>
            </div>
        )
}

export default App;